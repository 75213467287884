import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    saveProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProject', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectTask', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProject', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectTask', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectName(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectName', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectClient', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    
    archiveProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('archiveProject', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    updateProjectDescription(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectDescription', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskDueDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskDueDate', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskAssigneUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskAssigneUser', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskPriority(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskPriority', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskIsCompleted', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectRoles(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectRoles', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectContacts(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectContacts', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getProjects', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjectFolders(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getProjectFolders', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjectNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getProjectNotes', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateProjectStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectStatus', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    updateProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateProjectFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateProjectFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    dropdownUserAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownUserAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dropdownCompanyAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownCompanyAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getProjectById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getProjectById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('projectUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    projectContactList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('projectContactList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    projectContactClient(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .post('projectContactClient', queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    
    getProjectClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getProjectClients', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjectDataFromClockify(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getProjectDataFromClockify', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientIdProjectIdReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getClientIdProjectIdReports', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getClientAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getClientAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createProjectBoard(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('createProjectBoard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    editProjectBoard(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('editProjectBoard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteProjectBoard(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deleteProjectBoard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    getProjectBoards(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getProjectBoards', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientIdProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getClientIdProjects`, { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    
  },
};
